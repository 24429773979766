<template>
  <div class="forgetten">
    <div class="title">Forgetten Password</div>
    <div class="font">
      <p>
        Your account and contact information are used to log in to the site.
      </p>
      <p>Please save the password in a safe place.</p>
    </div>
    <div class="form col-sm-12 col-xs-12 col-lg-10 col-xl-10">
      <div class="single">
        <section>Email</section>
        <input type="text" placeholder=" Email" v-model="ruleForm.email" />
      </div>

      <div class="single">
        <section>Verification code</section>
        <div class="single-flex d-lg-flex d-xl-flex">
          <input
            class="col-lg-7 col-xl-7 col-sm-12 col-xs-12"
            type="text"
            placeholder="Verification code"
            v-model="ruleForm.code"
          />
          <div
            class="code-button col-lg-4 col-xl-4"
            :class="Traem ? 'code-button' : 'code-button1'"
            @click="gain"
          >
            {{ Ytxfont }}
          </div>
        </div>
      </div>

      <div class="single">
        <section>New password</section>
        <input
          type="password"
          placeholder="New password"
          v-model="ruleForm.password"
        />
      </div>
      <div class="single">
        <section>Confirm Password*</section>
        <input
          type="password"
          placeholder="Confirm Password*"
          v-model="ruleForm.newPassword"
        />
      </div>

      <div class="button" @click="path()">Next Step</div>
    </div>
  </div>
</template>
<script >
import { send, check } from "network/email";
import { resetpwd } from "network/user";
export default {
  data() {
    return {
      ruleForm: {
        email: "",
        code: "",
        password: "",
        newPassword: "",
      },
      Traem: true,
      Ytxfont: "Get verification code",
    };
  },
  created() {},
  methods: {
    path() {
      if (this.ruleForm.email == "") {
        this.$notify({
          message: "Please enter your emial",
          type: "error",
          duration: 3000,
          position: "top-left",
          showClose: false,
          offset: 300,
        });
        return;
      } else if (this.ruleForm.code == "") {
        this.$notify({
          message: "Please enter your Verification code",
          type: "error",
          duration: 3000,
          position: "top-left",
          showClose: false,
          offset: 300,
        });
        return;
      } else if (this.ruleForm.password && this.ruleForm.newPassword == "") {
        this.$notify({
          message: "Please enter your password",
          type: "error",
          duration: 3000,
          position: "top-left",
          showClose: false,
          offset: 300,
        });
        return;
      } else if (this.ruleForm.password != this.ruleForm.newPassword) {
        this.$notify({
          message: "The two input results are different",
          type: "error",
          duration: 3000,
          position: "top-left",
          showClose: false,
          offset: 300,
        });
        return;
      } else if (this.ruleForm.password.length < 6) {
        this.$notify({
          message: "Passwords need a minimum of 6 digits",
          type: "error",
          duration: 3000,
          position: "top-left",
          showClose: false,
          offset: 300,
        });
        return;
      }

      resetpwd(
        this.ruleForm.email,
        this.ruleForm.password,
        this.ruleForm.code
      ).then((res) => {
        if (res.data.code == 1) {
          this.$notify({
            message: res.data.msg,
            type: "success",
            duration: 3000,
            position: "top-left",
            showClose: false,
            offset: 300,
          });
          this.$router.push("/signIn");
        } else {
          this.$notify({
            message: res.data.msg,
            type: "error",
            duration: 3000,
            position: "top-left",
            showClose: false,
            offset: 300,
          });
        }
      });
    },
    gain() {
      send(this.ruleForm.email, "resetpwd").then((res) => {
        if (res.data.code == 1) {
          this.$notify({
            message: res.data.msg,
            type: "success",
            duration: 3000,
            position: "top-left",
            showClose: false,
            offset: 300,
          });

          if (this.Traem) {
            this.Traem = false;
            let langtime = 60;
            let than = this;
            let a = setInterval(function () {
              if (langtime == 0) {
                than.Traem = true;
                than.Ytxfont = "Get verification code";
                clearInterval(a);
              } else {
                langtime--;
                than.Ytxfont = `  Resend in ${langtime} seconds `;
              }
            }, 1000);
          } else {
            return false;
          }
        } else {
          this.$notify({
            message: res.data.msg,
            type: "error",
            duration: 3000,
            position: "top-left",
            showClose: false,
            offset: 300,
          });
        }
      });
    },
  },
};
</script>
<style scoped>
p {
  margin: 0;
}
.forgetten {
  border: 1px solid #ededed;
  box-shadow: 0.1rem 0.1rem 0.1rem #ededed;
  margin-top: 0.5rem;
  padding: 0.5rem;
}

.title {
  color: #333333;
  font-size: 0.8rem;
  text-align: center;
  margin-top: 1rem;
}
.font {
  text-align: center;
  color: #737373;
  font-size: 0.4rem;
  margin-top: 1rem;
}
.font p:last-child {
  margin-top: 0.25rem;
}
section {
  color: #666666;
  font-size: 0.4rem;
  font-weight: 600;
}
.single {
  margin-top: 0.75rem;
}

.form {
  margin: 0.25rem auto;
}
.single-flex {
  justify-content: space-between;
  margin-top: 0.25rem;
}
.single input {
  width: 100%;
  border: 1px solid #ededed;
  border-radius: 0.2rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.4rem;
  color: #999999;
  margin-bottom: 0.25rem;
}
.remenber input {
  width: 0.6rem;
  height: 0.6rem;
  margin-right: 0.5rem;
}

.button {
  margin: 1rem auto;
  max-width: 5rem;
  font-size: 0.4rem;
  color: #fff;
  padding: 0.3rem 1rem;
  background: #4d4d4d;
  border-radius: 0.2rem;
  text-align: center;
  cursor: pointer;
}
.button:hover {
  background: #ff7f00;
  color: #fff;
}
.code-button {
  font-size: 0.4rem;
  color: #ff7f00;
  padding: 0.3rem 0.5rem;
  border: 1px solid #ff7f00;
  border-radius: 0.2rem;
  text-align: center;
  margin-bottom: 0.25rem;
  cursor: pointer;
}
.code-button1 {
  font-size: 0.4rem;
  color: #fff;
  padding: 0.3rem 0.5rem;
  background: #e0e0e0;
  border: 1px solid #e0e0e0;
  border-radius: 0.2rem;
  text-align: center;
  margin-bottom: 0.25rem;
  cursor: pointer;
}
</style>