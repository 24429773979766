import { request } from "./request";


export function send(email, event) {
    return request({
        url: '/api/ems/send',
        params: {
            email: email,
            event: event,
        }
    })
};

export function check(email, event, captcha) {
    return request({
        url: '/api/ems/check',
        params: {
            email: email,
            event: event,
            captcha: captcha,
        }
    })
};